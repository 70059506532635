#deleteImg {
    padding: 15px;
    background:  var(--second-color);;
    border-radius: 20px;
    width: 63px;
    height: 63px;
}

.editSendDateDatePicker {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 10px;
}


.editDateStyle {
    font-size: 25px;
    line-height: 38px;
    color: #000000;
}

.editDateLabel {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: rgba(0, 0, 0);
    margin-left: 3px;
    margin-bottom: 10px;
}

.footerConfirmPopUpBtnsCancel {
    background: #FBFDFE;
    border: 0.762376px solid;
    border-radius: 3.81188px;
    color: #000000;
    border-radius: 4px;
    font-size: 16px;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
}


.footerConfirmPopUpBtnsOk {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background:  var(--main-color);
    border-radius: 3.81188px;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
}

.confirmQuestion {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    margin: 0 auto;
    text-align: center;
}

.form-group.ar {
    direction: rtl;
    text-align: start;
}

/* .footerPopUpBtns {
    background-color:  var(--main-color);
    color: white;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
} */

.paymentMethodDiv {
    display: flex;
    gap: 10px;
    align-items: center;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color:  var(--second-color) !important;
}

.css-u4tvz2-MuiFormLabel-root.Mui-focused {
    color: black !important;
}

.css-u4tvz2-MuiFormLabel-root {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 140% !important;
    color: #000000 !important;
    margin-top: 10px !important;
}

@media (max-width: 1200px) {
    .packList {
        overflow-x: scroll;
    }

}

@media (max-width: 600px) {
    .packList {
        overflow-x: scroll;
    }
}