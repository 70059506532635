table {
    width: 100%;
    overflow: auto;
}

/* table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 0.962px solid #EAECF0;
} */

td {
    border-width: 1px 0px;
    border-style: solid;
    border-color: rgba(220, 223, 227, 0.5);
    padding: 16px;
    text-align: center;
}

th {
    background-color: #F9F9FF;
        color: #7482AE;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.075px;
        letter-spacing: -0.199px;
    text-align: center;
    padding: 16px;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.nextPrevBtns {
    background-color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background:  var(--second-color);;
    color: white;
    border: 1px solid  var(--second-color);;
    border-radius: 8px 8px 8px 8px;
}

.loadingDivStyle {
    display: flex;
    justify-content: space-between;
}

.loadingDivStyle span {
    font-weight: bold;
    font-size: 20px;
    color: black;
}

.nextPrevBtns:disabled {
    background-color: #ccc6c6;
    ;
    color: white;
    border: 0;
}

.paginationPageNumbers {
    display: contents;
}

.paginationPageNumbers li {
    display: inline;
    margin-right: 10px;
    margin-left: 10px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px 8px 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    cursor: pointer;
}

.paginationPageNumbers li.active {
    background:  var(--second-color);;
    border: 1px solid  var(--second-color);;
    border-radius: 8px 8px 2px 8px;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
}


.totalNumberOfPages {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #747474;
}

.tableFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.loadingStyle {
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.tableFooter.ar {
    direction: ltr;
}


/* .pagesNumbers:disabled
{
    background-color: darkgray;
} */

input[type=checkbox] {
    accent-color:  var(--second-color);;
    width: 20px;
    height: 20px;
}

.tableComponantHeader
{
    color: #2B3674;
        font-size: 17.075px;
        font-style: normal;
        font-weight: 700;
        line-height: 22.767px;
        /* 133.333% */
        letter-spacing: -0.342px;
        margin-bottom: 16px;
}