.loginFrameContainer {
    display: flex;
    justify-content: center;
    padding: 40px;
    /* border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(138, 142, 148, 0.25); */
    width: 98%;
    /* max-width: 500px; */
    margin: 0 auto;
    padding-top: 48px;
    margin-top: 20px;

}

.loginMainContainer {
    background-size: cover;
    background-position: center;
    /* Set the background position to bottom */
    background-repeat: no-repeat;
    height: 100vh;
    /* Set the height to 100% of the viewport height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}




.captchaDiv {
    display: flex;
    justify-content: start;
    margin-top: 20px;
}

.forgetPassword {
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-top: 10px;
    text-align: right;
    text-decoration: underline;

}

.forgetPassword span {
    cursor: pointer;
}

.loginFrameContainer .mainTitle {
    text-align: start;
    font-size: 22px;
    font-weight: 500;
}

.loginFrameContainer .mainTitle.ar {
    direction: rtl;
}

.loginFrameContainer .subTitle {
    /* max-width: 350px; */
    color: #444;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 16px;
    margin-bottom: 32px;

}

.loginFrameContainer .subTitle.ar {
    direction: rtl;
}

.form-group.ar {
    direction: rtl;
}

.loginFrameContainer #mainLogo {
    margin: auto;
    display: block;
    margin-bottom: 20px;
}

.loginFrameContainer .input {
    font-size: 14px;
}

.loginFrameContainer #loginBtn {
    padding: 13px 45px;
    border-radius: 6px;
    font-size: 16px;
    background-color:  var(--main-color);
    display: block;
    color: white;
    text-align: center;
    width: 100%; 
    height: 44px;
    outline: none;
    border: none;
}

.loginFrameContainer .secondTitle {
    font-size: 15px;
    color: #888888;
    text-align: start;
    margin-bottom: 20px;
    display: flex;
    width: 100%; 
    align-items: baseline;
    justify-content: space-between;
}

.loginFrameContainer .secondTitle.ar {
    direction: rtl;
    width: 415px;

}

.loginSignUpFormLink {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    margin-top: 10px;
    text-align: right;
    text-decoration: underline;
}

.formLoginSignUp {
    max-width: 420px;
    max-height: 400px;
}

input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

#submitForm {
    width: 420px;
}

.form-group {
    margin-bottom: 10px !important;
}

.loginHeader {
    display: flex;
    justify-content: right;
    width: 100%;
    height: 30px;
    margin-right: 34%;
}

.loginFrameContainer #erorrMsg {
    color:  var(--main-color);
    margin-top: 20px;
}

.split-screen {
    display: flex;
    height: 100%;
}

.right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* background:url("../../../public/images/loginBG.png"); */
    /* Set a background color if needed */
}

.loginLogoBG {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    z-index: 99;
}

#mainLogoMainPage {
    width: 100%;
    height: 100%;
}

#mainLoginLogo {
    position: absolute;
    width: 100%;
    height: 640px;
}


.left-side {
    flex: 1;
    padding: 20px;
    flex-direction: column;
    justify-content: start;
    margin-left: 10%;
    margin-top: 2%;

}

.mainPageLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 399px;
    height: 183px;
    border-radius: 16px;
    border: 1px solid #ffffff8f;
    background: rgba(255, 234, 234, 0.25);
    box-shadow: 0px 4px 15.7px 0px rgba(121, 121, 121, 0.08);
    backdrop-filter: blur(13px);

}

/* Media query for mobile devices */
@media (max-width: 768px) {
    #mainLoginLogo {
        position: relative !important;
        margin-top: -10%;
    }

    .split-screen {
        flex-direction: column-reverse;
    }

    .formLoginSignUp {
        width: 90%;
        margin-top: 20px;

    }

    .form-control {
        width: 100%;
    }

    .forgetPassword {
        width: 100%;
        ;

    }

    .loginFrameContainer #loginBtn {
        width: 100%;
        ;
    }

    .loginFrameContainer .secondTitle {
        width: 100%;
    }


    .left-side {
        margin-left: 0;
        margin-top: 10%;

    }

 


    .left-side,
    .right-side {
        flex: none;
        /* Reset flex property for equal width on mobile */
        width: 100%;
        /* Make both sides take full width on mobile */
    }

    /* .left-side
    {
        height: 200px;
    } */
    #submitForm {
        width: 100%;
    }
}