.transactionContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.transactionsTableContainer {
    background-color: white;
    padding-bottom: 10px;
}

.transactionContainer .titletransactions {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

#packagesHistoryExport
{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tableHeaderFilter .searchFilter {
    width: 120px;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.transactionContainer .exportBtn {
    width: 128px;
    height: 44px;
    background:  var(--second-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;

}

.editable-cell-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .transactionContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .transactionsTableContainer {
        overflow-x: scroll;
    }

}

@media (max-width: 1200px) {

    .transactionContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

}

@media (max-width: 600px) {
    .transactionContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .transactionContainer .transactionsTableContainer {
        overflow-x: scroll;
    }

    .transactionsTableContainer {
        overflow-x: scroll;
    }

    .transactionContainer .exportBtn {
        margin-top: 20px;
        width: 100%;
    }
    #packagesHistoryExport{
        flex-direction: column;
    }
}