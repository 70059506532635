.allGatewaysReport .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.allGatewaysReport .tableFilter {
    display: flex;
    gap: 10px;
}

.allGatewaysReport .titleReport {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.allGatewaysReport .titleFilter {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.allGatewaysReport .filterList {
    border: 1px solid #d1d1d1;
    color: #252b42;
    font-size: 14px;
    font-weight: 600;
    height: 45px;
    letter-spacing: .2px;
    line-height: 24px;
    margin-right: 10px;
    width: 128px;
}

.tableDiv {
    background: white !important;
    padding-bottom: 10px;
}

.gatewaysStatisticsContainer{
    display: flex;
    gap: 30px;
    align-items: center;
}

.gatewaysStatisticsContainer .pieChartCard {
    border-radius: 14px;
    background: #FFF;
    height: 280px;
    padding: 20px;
    text-align: start;
    padding-bottom: 40px;
}

.gatewaysStatisticsContainer .pieChartCard span {
    color: #2B3674;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.921px;
    letter-spacing: -0.228px;

}

.statisticsCardsMainDiv{
    display: flex;
    flex: auto;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
}



.tableHeaderFilter .searchFilter {
    width: 120px;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.allGatewaysReport .exportBtn {
    width: 128px;
    height: 44px;
    background:  var(--second-color);;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;

}


.allGatewaysReport .spinnerStyle {
    margin-top: 20px;
    margin-left: 20px;

}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .allGatewaysReport .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .allGatewaysReport .tableFilter {
        flex-direction: column;
        justify-content: space-around;
    }

    .allGatewaysReport .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .allGatewaysReport .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }
    .statisticsCardsMainDiv{
        flex-direction: column;
    }

    .gatewaysStatisticsContainer{
        flex-direction: column;
        gap: 0px;
        margin-bottom: 16px;
    }

}


@media (max-width: 400px) {


    .allGatewaysReport .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .allGatewaysReport .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }
    .statisticsCardsMainDiv{
        flex-direction: column;
    }  
    .gatewaysStatisticsContainer {
          flex-direction: column;
          gap: 0px;
          margin-bottom: 16px;
      }
}