 .modal-dialog {
     max-width: 650px;
 }



 

 .campaignMsgTitle
 {
    color: #0F0F0F;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
        
 }

.resendPopUpBody
{
   text-align: center;
   color: #0F0F0F;
   font-size: 19px;
   font-style: normal;
   font-weight: 300;
   line-height: 32px;
}