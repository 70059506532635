.modal-header {
    border-bottom: 0;
    margin: 0 auto;
}

.modal-footer {
    border-top: 0;
    /* margin: 0 auto; */
}

.modal-body {
    border-top: 0;
    margin: 0 auto;
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    width: 90%;
}

.modalBody {
    margin: 0 auto;
}

.footerPopUpBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    color: white;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border: none;
    width: 180px;
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 10px;
}

.closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FBFDFE;
    border: 0.762376px solid var(--main-color);
    border-radius: 3.81188px;
    color: var(--main-color);
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    width: 180px;
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 10px;
}

.closeBtn:hover {
    background-color: var(--main-color);
    color: white;
}

@media (max-width: 1024px) {
.modal-footer {
    display: flex;
    justify-content: center;
    }
}


@media (max-width: 400px) {
.modal-footer {
        display: flex;
        justify-content: center;
    }
}