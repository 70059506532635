.sendersContainer .tableMainTitles {
    display: flex;
    justify-content: end;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sendersContainer .titleSenders {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.sendersContainer .senderStatusActive {
    color: #91C561;
    padding: 10px;
    background: rgba(145, 197, 97, 0.20);
    border-radius: 40px;
    border: none;
}

.sendersContainer .senderStatusPending {
    color: #D6A243;
    padding: 8px;
    background: rgba(214, 162, 67, 0.20);
    border-radius: 40px;
    border: none;
}

.tableHeaderFilter .searchFilter {
    width: 120px;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;
}

.sendersContainer .addSenderBtn {
    width: 128px;
    height: 44px;
    background:  var(--second-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;

}

.sendersContainer .downloadLetterBtn {
    width: 158px;
    height: 44px;
    background:  var(--second-color);;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;
}

.formCard {
    width: 100%;
    height: 202px;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 440px;
    overflow: auto;
    background: rgba(221, 51, 51, 0.02);
    border: 1px dashed  var(--second-color);;
    border-radius: 4px;
    margin: 0 auto;
}

.uploadFileIcon {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.uploadFileIcon {
    align-items: center;
    padding: 25px;
}

.uploadInfo {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #0F0F0F;
}

.senderNameLabel {
    font-size: 16px;
    font-weight: 500;

}

.uploadFileType {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #676767;
}

.filesUploadDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.inputFilesUpload {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.uploadCloud {
    width: 68.78px;
    height: 59.59px;
}

.enterDataInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
}

.enterDataInput.ar {
    direction: rtl;
}

.sendersTableContainer {
    background-color: white;
    padding-bottom: 10px;
}


.clientDLStyle {
    width: 100%;
    max-width: 440px;
    margin-top: 10px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #ced4da;
}

.form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.uploadSenderDataBtn {
    width: 124px;
    height: 40px;
    background:  var(--second-color);;
    border-radius: 3.81188px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}


/* Responsive Media Query */
@media (max-width: 1200px) {
    .sendersContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .sendersContainer .addSenderBtn {
        margin-top: 20px;
        width: 100%;
    }

    .sendersContainer .downloadLetterBtn {
        margin-top: 20px;
        width: 100%;
    }

}

@media (max-width: 600px) {
    .sendersContainer .sendersTableContainer {
        overflow-x: scroll;
    }

    .sendersContainer .addSenderBtn {
        margin-top: 20px;
        width: 100%;
    }

    .sendersContainer .downloadLetterBtn {
        margin-top: 20px;
        width: 100%;
    }
}