.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 90vh;
    flex-direction: column;
}

.waitingScreenTitle {
    font-weight: 900;
    font-size: 50px;
    line-height: 65px;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
}

.waitingScreenSubTitle {

    font-weight: 400;
    font-size: 25px;
    line-height: 60px;
    color: rgba(0, 0, 0, 0.8);
}