.statisticsCardStyle {
    padding-right: 8px;
    padding-left: 12px;
    padding-bottom: 14px;
    padding-top: 14px;
    gap: 5px;
    height: 80px;
    width: 100%;
    border-radius: 14px;
    background: #FFF;
    border: 0px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: start;
}

.statisticsCardIconContainer {
    border-radius: 50px;
    padding: 10px;
    background-color: #F4F7FE;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.statisticsCardContent {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

}

.statisticsCardTitle {
    color: #7482AE;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.075px;
    letter-spacing: -0.2px;
}

.statisticsCardValue {
    color: #2B3674;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.767px;
    letter-spacing: -0.32px;
}