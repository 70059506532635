.datePickerDiv {
    display: flex;
    margin-top: -20px;
}

.datePickerDiv .datePickerStyle {
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    padding: 10px;
}

.datePickerDiv .startEndDateTitle {
    font-size: 16px;
    color: black;
    font-weight: 600;
}

@media (max-width: 1100px) {
    .datePickerDiv {
        flex-direction: column;
        margin-top: 20px;
    }
}

@media (max-width: 400px) {
    .datePickerDiv {
        flex-direction: column;
        margin-top: 20px;
    }
}