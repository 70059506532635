.listLabelStyle
{
    display: flex;
    justify-content: start;
    font-size: 13px;
    color: #444;
}

.listLabelStyle.ar
{
    direction: rtl;
}