.dateRangFieldStyle {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background: #FFF;
    width: 187px;
}

.dateRangFieldStyleFonts {
    color: #7482AE;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.22px;
}
.MuiSvgIcon-root
{
    width: 15px !important;
    height: 15px !important;
}
.MuiInputBase-input
{
    color: #7482AE !important;
        font-size: 11px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        letter-spacing: -0.22px !important;
}

.MuiInput-underline:before
{
    border-bottom: 0 !important;
}