#packagesImg {
    padding: 15px;
    background:  var(--second-color);;
    border-radius: 20px;
    margin-bottom: 10px;
}



.form-group.ar {
    direction: rtl;
    text-align: start;
}
/* 
.footerPopUpBtns {
    background-color:  var(--main-color);
    color: white;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
} */

.packList.ar {
    direction: rtl;
}

.paymentMethodDiv {
    display: flex;
    gap: 10px;
    align-items: center;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color:  var(--second-color) !important;
}

.css-u4tvz2-MuiFormLabel-root.Mui-focused {
    color: black !important;
}

.css-u4tvz2-MuiFormLabel-root {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 140% !important;
    color: #000000 !important;
    margin-top: 10px !important;
}

@media (max-width: 1200px) {
    .packList {
        overflow-x: scroll;
    }

}

@media (max-width: 600px) {
    .packList {
        overflow-x: scroll;
    }
}