.chartStyle {
    /* max-width: 772px; */
    background: #FFFFFF;
    border: 0px solid #EEEEEE;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 14.229px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    height: 360px;
    justify-content: flex-start;
    overflow: auto;
 
}

.dashboardLineChartCard {
    width: 100%;
}

.colsSpaces {
    padding-left: 16px !important;
    padding-right: 0px !important;
}


.oneToOneCampignContainer {
    color: #05CD99;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 15.386px;
    background: #ECFDF3;
    width: 98.2px;
    height: 19.8;
    padding: 5px;
}

.bulkCampignContainer {
    color: #2B3674;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 15.386px;
    background: #ECF1FD;
    width: 98.2px;
    height: 19.8;
    padding: 5px;
}

.tableDiv {
    overflow: auto;
}

.lineChartContainer {
    width: 100%;
    height: 250px;
}

.chartSmsCountLabel {
    color: #2B3674;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.881px;
    letter-spacing: -0.48px;
    display: flex;
    text-align: start;
    justify-content: space-between;
    padding-right: 16px;
    padding-bottom: 8px;
}

.chartLabel {
    color:  var(--chart-color);;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 8px;
}

.redCircle {
    width: 8px;
    height: 8px;
    background-color:  var(--chart-color);;
    border-radius: 50%;
}

.dashboardContainer {
    padding-top: 10px;
    padding-bottom: 10px;

}

.row {
    padding-right: 16px !important;
}

.dashboardContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.tableCardHeader {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 16px;
    margin-bottom: 16px;
}

.tableCardHeaderIcon {
    width: 30px;
    height: 30px;
    border-radius: 7.115px;
    background-color: #F4F7FE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.event-mark {
    display: block;
    margin: 0 auto;
    color: red;
    /* Customize the color based on your design */
}

.tableCardHeaderTitle {
    color: #2B3674;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.767px;
    letter-spacing: -0.342px;
    margin-left: 25px;
}

.dashboardContainer .titleAccounts {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.statisticsNumbersDiv {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.apiIntegrationCard {
    display: flex;
    flex-direction: column;
    gap: 45px;
}

.apiIntegrationCard span {
    color: #2B3674;
    font-size: 17.075px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.767px;
    letter-spacing: -0.342px;
}

.apiIntegrationCard div {
    color: #7482AE;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.075px;
    letter-spacing: -0.199px;
    width: 200px;
}

.apiIntegrationCard button {
    width: 100%;
    padding: 10px;
    background-color: #000;
    color: white;
    border-radius: 15px;

}

.apiCardsIcons {
    width: 64px;
    height: 64px;
}

.dashboardRightSideCard {
    border-radius: 14px;
    background: #FFF;
    height: 360px;
    padding: 20px;
    text-align: start;
    padding-bottom: 40px;
    /* overflow: auto; */
}

.sendersDashboardContainer {
    max-height: 300px;
    height: 100%;
    overflow-y: auto;
}

.senderCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 11.383px;
    border: 0.711px solid var(--Stroke, #D9D9D9);
    background: #FFF;
}

.senderCard span {
    margin-right: 5px;
}

.addNewSenderBtnCard {
    width: 30px;
    height: 30px;
    border-radius: 7.115px;
    background: #F4F7FE;
    display: flex;
    justify-content: center;
    align-items: center;
}



.senderActiveState {
    width: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15.386px;
    background: #ECFDF3;
    padding: 2px 8px;
    color: #05CD99 !important;
    text-align: center;
    font-size: 9px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.senderPendingState {
    width: 60px !important;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-radius: 15.386px;
    background: #FDF7EC;
    color: orange !important;
    padding: 2px 8px;
        text-align: center;
            font-size: 9px !important;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
}

.circleGreen {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: #05CD99 !important
    
}

.circleOrange {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: orange !important;
}

.dashboardRightSideCard span {
    color: #2B3674;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.921px;
    letter-spacing: -0.228px;

}

.pieChartContainer {
    display: flex;
    justify-content: center;
    max-width: 250px;
    height: 235px;
    margin-top: 16px;
    margin: 0 auto;
}

.noDataStyle {
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
}

.statisticsCardIconContainer {
    border-radius: 50px;
    padding: 10px;
    background-color: #F4F7FE;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statisticsCardContent {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

}

.dashboardContainer .filterList {
    width: 128px;
    height: 45px;
    border: 1px solid #D1D1D1;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #252B42;
    margin-right: 10px;
}


.statisticsCardTitle {
    color: #7482AE;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.075px;
    letter-spacing: -0.2px;
}



.dashboardContainer .exportBtn {
    width: 128px;
    height: 44px;
    background:  var(--second-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    float: right;
}

/* Add this to your CSS file or styling solution */
.cardTable {
    width: 100%;
    border-collapse: collapse;
    /* margin-top: 20px; */
    overflow: hidden;
}

.cardTable th,
.cardTable td {
    padding: 8px;
    text-align: start;
    border: none;
    border-bottom: 1px solid #E9EDF7;
}

.cardTable th {
    background-color: transparent;
    color: #7482AE;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.075px;
    letter-spacing: -0.199px;
}

.cardTable td {
    color: #2B3674;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.075px;
    letter-spacing: -0.199px;
    width: 400px;

}

.cardTable tbody tr:nth-child(even) {
    background-color: transparent;
    /* Remove background color from even rows */
}

.loadingBar {
    width: 100%;
    height: 7px;
    background-color: #ccc;
    position: relative;
    border-radius: 50px;
}

.loadingBar::before {
    content: '';
    display: block;
    height: 100%;
    width: calc(var(--percentage));
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
}

/* Optional: Add styling for odd rows */
/* .cardTable tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
} */


.cardsIcons {
    width: 20px;
  

}

.statisticsCardDate {
    display: flex;
    gap: 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    opacity: 0.3;
    color: #000000;
    margin-top: 5px;
}



/* Responsive Media Query */
@media (max-width: 768px) {
    .row {
        margin-bottom: 16px;
    }

    .chartStyle {
        overflow: auto;
    }

    .sendersDashboardContainer {
        overflow: hidden;
    }

    .dashboardRightSideCard {
        overflow: auto;
        margin-top: 16px;
    }

    .dashboardRightSideCard span {
        margin-top: 16px;
    }

    .dashboardContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .dashboardContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .statisticsNumbersDiv {
        flex-direction: column;
    }

    .dashboardContainer .adminReport {
        overflow-x: scroll;
    }

    .dashboardContainer .exportBtn {
        margin-top: 20px;

    }


}