.contactsContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.contactsContainer .titleContacts {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.senderTitleBtns {
    display: flex;
    align-items: center;
}

.contactsContainer .filterList {
    width: 128px;
    height: 45px;
    border: 1px solid #D1D1D1;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #252B42;
    margin-right: 10px;
}


.groupNumberListContainer
{
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    margin-top: 10px;
}

.groupNumberListStyle
{
    width: 100%;
    height: 200px;
    max-width: 440px;
    margin: 0 auto;
    border: 1px solid #f0f0f0;
}


.contactsContainer .spinnerStyle {
    margin-top: 20px;
    margin-left: 20px;

}

.downloadIconStyle {
    background-color: rgba(2, 164, 67, 0.15);
    border-radius: 5px;
    padding: 8px;
}

.editIconStyle {
    background: rgba(70, 154, 245, 0.22);
    border-radius: 5px;
    padding: 8px;
    color: #007AFF;
    height: 35px;
    cursor: pointer;
}

.addGroupFooterBtn {
    width: 124px;
    height: 40px;
    background:  var(--second-color);;
    border-radius: 3.81188px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.contactsActions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.fa-file-download {
    color: #02A443;
    font-size: 20px;
}

.fa-edit {
    font-size: 20px;
}

.btnsDiv {
    display: flex;
    gap: 10px;
}

.btnsDiv button {
    height: 44px;
    padding-left: 20px;
    padding-right: 20px;
    background:  var(--second-color);;
    border-radius: 3.81188px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.contactsActions i {
    cursor: pointer;
}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .contactsContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .contactsContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }


    .btnsDiv {
        flex-direction: column;
    }


}


@media (max-width: 400px) {


    .contactsContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

}