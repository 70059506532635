.scheduleContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.scheduleContainer .tableFilter {
    display: flex;
    gap: 10px;
}

.scheduleContainer .titleReport {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.tableDiv
{
    border-radius: 14.229px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
        background-color: white;
        padding-bottom: 10px;
}

.scheduleContainer .titleFilter {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.scheduleContainer .filterList {
    border: 1px solid #d1d1d1;
    color: #252b42;
    font-size: 14px;
    font-weight: 600;
    height: 45px;
    letter-spacing: .2px;
    line-height: 24px;
    margin-right: 10px;
    width: 128px;
}

.deleteIconStyle {
    background-color: rgba(221, 51, 51, 0.15);
    border-radius: 5px;
    padding: 8px;
    color: rgba(221, 51, 51, 0.80);
}

.editIconStyle {
    background: rgba(70, 154, 245, 0.22);
    border-radius: 5px;
    padding: 8px;
    color: #007AFF;
    height: 35px;
}

.smsStatus {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.sentStatus {
    background: rgb(106, 195, 0);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.unSentStatus {
    background: rgb(255, 186, 109);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.failedStatus {
    background: rgb(136, 2, 2);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.viewDetailsStyle {
    /* display: flex;
     justify-content: center;
     align-items: center;
     height: 40px;
     color: #0F0F0F;
     text-align: center;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: 20px;
     border-radius: 4px;
     background-color: #F2F2F2;
     cursor: pointer; */
    color: #478ad1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}

.viewDetailsStyle:hover {
    cursor: pointer;
    text-decoration: underline;

}






.tableHeaderFilter .searchFilter {
    width: 120px;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.scheduleContainer .exportBtn {
    width: 128px;
    height: 44px;
    background:  var(--second-color);;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;

}


.scheduleContainer .spinnerStyle {
    margin-top: 20px;
    margin-left: 20px;

}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .scheduleContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .scheduleContainer .tableFilter {
        flex-direction: column;
        justify-content: space-around;
    }

    .scheduleContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .scheduleContainer .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }

}


@media (max-width: 400px) {


    .scheduleContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .scheduleContainer .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }
}