.sidebar {
    width: 20%;
    background: #FFFFFF;
    box-shadow: 7px -1px 12px rgba(0, 0, 0, 0.11);
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar.showSideBarMobile {
    width: 100%;
    position: fixed;
    z-index: 1000;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    left: 0;
}



.sidebar.ar {
    box-shadow: 7px -1px 12px 12px rgba(0, 0, 0, 0.11);
}

.sideBarLogoContainer {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 2px 16px 0px rgba(112, 144, 176, 0.08);

}


.sidebar .mainLogo {
    display: flex;
    width: 160px;
}

.sidebar .miniLogo {
    display: none;
}

.container {
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;
    list-style: none;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 90px;
}

.messagesTypeDiv {
    margin-bottom: 50px;
}

.icon {
    margin-top: 2px;
    color: #7482AE;
}

.subMenu {
    background-color: #f0f0f0;
}


.sidebar .nav-links {
    padding: 0;
}

.sidebar .logo-details {
    height: 112px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #F4F7FE;
}

.bars {
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    color: #707070;
}
.mobileBars {
    display: none;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    color: #707070;
}

.sidebarHide .logo-details {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    height: 50px;
}

.sidebar .nav-links li a {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    text-align: center;
    border-radius: 5px;
}

.sidebarIcon {
    margin-left: 23px;
}

.sidebarIcon.ar {
    margin-right: 23px;
}

.sideBarSubMenuDiv {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    text-align: center;
    cursor: pointer;
    justify-content: space-between;
}



.subMenueActive {
    background:  var(--hover-color);
}

.sideBarSubMenuDiv:hover {
    background:  var(--hover-color);
}


.sidebar .nav-links li a.active {
    border-right: 5px solid  var(--main-color);
}

.sidebar .nav-links.ar li a.active {
    border-right: 0px;
    border-left: 5px solid  var(--main-color);
}


.sidebar .nav-links li a:hover {
    background:  var(--hover-color);
}

.sidebar .nav-links li i {
    min-width: 60px;
    text-align: center;
    font-size: 14px;
    color: #707070;
}


.links_name {
    color: #707070;
    margin-left: 10px;
    color: #2B3674;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.24px;
    margin-top: 2px;
}

.sidebarHide {
    width: 60px;

}



.sidebarHide.active {
    width: 60px;
}

.sidebarHide .links_name {
    display: none;
}

.sidebarHide .mainLogo {
    display: none;
}

.sidebarHide .miniLogo {
    display: flex;
    width: 50px;
    margin-top: 20px;
}

.sidebarHide .fa-comments {
    display: none;
}

/* Responsive Media Query */
@media (max-width: 768px) {
    /* .sidebar{
        width: 60px;
    } */

    .sideMenuMobileScreen{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sidebar .logo-details{
        flex-direction: column;
    }
    .showSideBarMobile .sideMenuMobileScreen{
        display: block;

    }

    .sidebar.showSideBarMobile .logo-details{
        flex-direction: row;
    }
    .showSideBarMobile .miniLogo{
        display: none !important;
    }
    .sidebar.showSideBarMobile .mainLogo{
        display: flex !important;
    }
    .showSideBarMobile .links_name,
    .showSideBarMobile .fa-comments,
    .showSideBarMobile .sideBarSubMenuDiv>div  {
        display: flex !important;
    }

    .sidebar.active {
        width: 220px;
    }

    .sidebar .miniLogo {
        display: block;
        width: 50px;
    }
    .fa-comments,
    .sideBarSubMenuDiv>div,
    .sidebar .mainLogo,
    .bars,
    .links_name  {
        display: none;
    }

    .mobileBars {
        display: block;
    }


}
