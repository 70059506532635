.labelStyle {
    font-size: 13px;
    color: #444;
}

.labelStyle.ar {
    float: right;
}

.form-control {
    width: 100%; 
    height: 48px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--neutral-almost-white, #EEE);
    /* background: var(--i-osbg, rgba(249, 249, 249, 0.80)); */
}

.form-control.ar {
    direction: rtl;
}

@media only screen and (max-width: 700px) {

.form-control {
        width: 100%;
    }


}