.addBusinessSectorBtn
{
    height: 46px;
    padding: 8px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background-color: var(--second-color);
    color: white;
}

.tableActionsCol
{
    display: flex;
    gap: 12px;
    justify-content: center;
}
.tableActionsCol i
{
    color: #7482AE;
    cursor: pointer;
}