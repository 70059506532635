.accountContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.createNewAccountContainer
{
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}

.createNewAccountBtn
{
    width: 128px;
    height: 44px;
    background:  var(--second-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;
}

.accountContainer .filterList {
    width: 128px;
    height: 45px;
    border: 1px solid #D1D1D1;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #252B42;
    margin-right: 10px;
}

.accountsTableContainer {
    border-radius: 14.229px;
    padding: 25px;
    background-color: white;
    padding-bottom: 10px;
}



.tableHeaderFilter .searchFilter {
    width: 120px;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.accountContainer .titleAccounts {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.transactionReportActions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.viewHistoryBtn {
    width: 119px;
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.8);
    background-color: white;
}

.viewHistoryBtn:hover {
    border: 1px solid white;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
}

.addBalanceBtn {
    width: 119px;
    height: 44px;
    border: 1px solid  var(--second-color);;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color:  var(--second-color);;
    background-color: white;
}


.addBalanceBtn:hover {
    border: 1px solid white;
    color: white;
    background-color:  var(--second-color);;
}




.TransactionBtn {
    box-sizing: border-box;
    width: 140px;
    height: 35px;
    left: 547px;
    top: 304px;
    margin-left: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    background-color: inherit;
}

.eyeIcon {
    background: rgba(174, 174, 174, 0.22);
    border-radius: 5px;
    padding: 8px;
    color: black;
    height: 35px;
    cursor: pointer;
}

.exportAccountsBtn {
    width: 128px;
    height: 44px;
    background: var(--second-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;

}
.titlepopup {
    width: 500px;
    height: 29px;
    left: 364px;
    top: 251px;

    margin-left: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20.1118px;
    line-height: 30px;

    /* text-primary */
    color: #404040;
}

.titlepopupActive {
    border-bottom: 4px solid  var(--second-color);;
    color:  var(--second-color);;
}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .accountContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .transactionsTableContainer {
        overflow-x: scroll;
    }
       .createNewAccountBtn {
        margin-top: 20px;
        width: 100%;
    }

}

@media (max-width: 600px) {
    .accountContainer .accountsTableContainer {
        overflow-x: scroll;
    }

    .transactionsTableContainer {
        overflow-x: scroll;
    }
       .createNewAccountBtn {
        margin-top: 20px;
        width: 100%;
    }

    .createNewAccountContainer
{flex-direction: column;
}
.exportAccountsBtn
{
    width: 100%;
}
}