.profile-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: transparent;

}

.profile-details img {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 3px;
}

.headerCardIcon {
    border-radius: 38px; 
    background: #F4F7FE;
}

.headerCardNote {
    color: #7482AE;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.075px;
    letter-spacing: -0.199px;
    margin-top: 6px;

}

.headerCardSmsContent {
    color:  var(--main-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}

.headerCardMoneyContent {
    color: #05CD99; 
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 4px;
}

.mainPageTitleHeader {
    color: #2B3674;
    font-size: 24.19px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.881px;
    letter-spacing: -0.484px;
}


.admin_amount {
    display: flex;
    justify-content: space-around;
    gap: 8px;
    align-items: center;
    padding: 0px 8px;
    border-radius: 24px;
    background: #FFF;
    margin-right: 16px;
    font-size: 18px;
    cursor: pointer;
    /* height: 40px; */
}

.admin_amount.ar {
    margin-left: 16px;
}

.userBalance {
    display: flex;
    justify-content: space-around;
    gap: 8px;
    align-items: center;
    padding: 0px 8px;
    border-radius: 24px;
    background: #FFF;
    margin-right: 16px;
    font-size: 18px;
    cursor: pointer;
    /* height: 40px; */
}



.admin_name {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.admin_email {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
}

.myProfileListStyle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #212121;
}

.profileIcon {
    margin-right: 10px;
}

.profileIcon.ar {
    margin-left: 10px;
}


.profileDropDownListStyle {
    background-color: transparent;
    color: black;
    outline: 0;
    border: 0;
}


.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255);
}

.btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255);
}

.dropDownItem:focus {
    background-color: rgba(221, 51, 51, 0.632);
    ;
}


.profileDropDownListStyle:hover {
    background-color: transparent;
    color: black;
    outline: 0;
    border: 0;
}


.balanceAndCreditDiv {
    display: flex;
}




.profileMenuStyle.ar {
    direction: rtl;
    text-align: start;
}



.profileMenuStyle {
    width: 270px;
    margin-top: 20px;
}

.logOutIcon {
    margin-right: 10px;
}

.logOutIcon.ar {
    margin-left: 10px;
}


.languageBtn {
    background-color: white;
    border: 0;
}

.languageDiv {
    margin-right: 10px;
}


nav {
    display: flex;
    justify-content: flex-end;
    height: 60px;
    background: transparent;
    margin-bottom: 16px;
}

@media only screen and (max-width: 700px) {

    .mainPageTitleHeader {
        margin-bottom: 10px;
    }

    .balanceAndCreditDiv {
        flex-direction: column;
        gap: 20px;
        /* margin-top: 125px; */
    }

    nav {

        margin-bottom: 150px;
    }

    .profile-details {
        flex-direction: column;
    }
    .userBalance,
    .admin_amount{
        height: 40px;
    }


}