.sendBulkSMS .uploadBtn {
    width: 180px;
    height: 36px;
    background:  var(--main-color);
    border-radius: 3.81188px;
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    width: 180px;
    height: 36px;
    margin-right: 10px;
    margin-left: 10px;
}

.sampleGuide {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.4);
    width: 577px;
    max-width: 90%;
}

.mainInfoSection1
{
    display: flex;
    justify-content: start;
    gap: 20px;
}

.campaignNameDiv {
    width: 47%;
    max-width: 670px;
}

.sendSmsInfoSectionTitle
{
    font-size: 18px;
    font-weight: 500;
    line-height: 29.88px;
    letter-spacing: -0.02em;
    color: #2B3674;
}

.msgLengthDiv {
    width: 90%;
    max-width: 670px;
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.4);
}

.sendBulkSMS .downloadBtn {
    width: 180px;
    height: 40px;
    background: #FBFDFE;
    border: 0.762376px solid #5C5E64;
    border-radius: 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #5C5E64;
}

.downloadBtnIcon
{
    margin-right: 4px;
    margin-left: 4px;
}

.uploadInputFileBtn
{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    background-color: #BA1319;

}



.form-group.ar {
    direction: rtl;
}

.cancelBtn {
    background-color: white;
    color:  var(--main-color);
    border: 2px solid  var(--main-color);
    border-radius: 4px;
    font-size: 16px;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.confirmationPopUpTitle {

    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    color: #161616;
}

.sendTesttMsgBtn {
    background-color:  var(--main-color);
    color: white;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.downloadWrongNumbersBtn
{
    background-color:  var(--main-color);
    color: white;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    width: 115px;
    padding: 5px;
}

.sendBulkSMS .testBtn {
    background-color: white;
    color: black;
    border-radius: 4px;
    font-size: 16px;
    width: 180px;
    height: 36px;
    font-weight: 600;
    border: 1px solid black;
}



.sendBulkSMS .uploadProgress {
    display: flex;
    width: 90%;
    max-width: 670px;
}

.sendBulkSMS .uploadSubTitle {
    font-size: 20px;
    color: black;
    margin-top: -15px;
}



.sendBulkSMS .uploadInfo {
    font-size: 14px;
    color: #888888;
    margin-top: -12px;
}

/* .sendBulkSMS .form {
    position: relative;
    width: 90%;
    height: 108px;
    max-width: 670px;
    border: 0.762376px dashed rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: block;
    overflow: auto;
} */

.isScheduleCheckBox {
    max-width: 670px;
    width: 90%;
    margin: 5px auto 5px auto;
    display: flex;
    align-items: baseline;
    gap: 20px;
}

.scheduleDate {
    max-width: 670px;
    flex-direction: column;
}


.scheduleDateStyle {
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.sendBulkSMS .messageTestArea {
    position: relative;
    width: 90%;
    height: 150px;
    max-width: 670px;
    padding: 10px;
    display: block;
    overflow: auto;
    margin-bottom: 4px;
    border: 1px solid #E0E0E0;
    resize: none;
}
.sendBulkSMS .form
{
    display: flex;
    flex-direction: column;
  
}
.formLabelStyle
{
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    letter-spacing: -0.02em;
    color: #7482AE;

}

.radioBtnsUploadNumberTypeContainer
{
    display: flex;
    gap: 20px;
}

.radioBtnsUploadNumberTypeContainer div
{
    display: flex;
    align-items: center;
    gap: 8px;
}

.radioBtnsUploadNumberTypeContainer div label
{
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    letter-spacing: -0.02em;
    margin-bottom: 3px !important;
    color: #7482AE;
}
.sendBulkSMS .form input
{
    border: 1px solid #E0E0E0;
    margin-right: -2px;
    width: 52%;
}
/* 
.sendBulkSMS .form div {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    color: black;
    font-family: Arial;
    position: absolute;
    top: 0;
    z-index: -1;
    background-color: white;
} */
/* 
.sendBulkSMS .form input {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
} */

.confirmationDiv {
    background: #FFFFFF;
    border-radius: 30px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
    margin-top: 20px;
    padding: 25px;

}

.totalNumbers {
    display: flex;
    align-items: center;
    gap: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.wrongNumbersError {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.dublicateNumbersError
{
    display: flex;
    align-items: center;
    gap: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.wrongNumbersErrorLabel
{
    display: flex;
    align-items: center;
    gap: 15px;
}

/* .sendBulkSMS .form:hover {
    background-color: rgba(186, 19, 25, 0.05);
} */

.sendBulkSMS .icons {
    width: 43px;
    height: 43px;
    margin-top: -15px;
    position: relative;
    top: 30px;
    left: -10px;
}


.css-13cymwt-control {
    padding: 10px;
}

.css-t3ipsp-control {
    padding: 10px;
}

.css-t3ipsp-control:hover {
    border-color: white !important;
}

.test {
    width: fit-content;
    height: fit-content;
}

.sendBulkSMS #senderDL {
    max-width: 670px;
    width: 90%;
    height: 58px;
    margin-top: 10px;
    border-radius: 0;
    color: #757575;
    cursor: pointer;
    display: block;
}

.sendBulkSMS #clientDL {
    max-width: 670px;
    width: 90%;
    margin-top: 10px;
    border-radius: 0;
    height: 58px;
    color: #757575;
    cursor: pointer;
    display: block;
}

.sendBulkSMS #senderDL:focus {
    box-shadow: none !important;
}

.sendBulkSMS #clientDL:focus {
    box-shadow: none !important;
}

.sendBulkSMS #groupDL {
    max-width: 670px;
    width: 90%;
    margin: 12px auto 12px auto;
    border-radius: 0;
    height: 58px;
    color: #757575;
    cursor: pointer;
    display: block;
}

.sendBulkSMS #groupDL:focus {
    box-shadow: none !important;
}

.sendBulkSMS #uploadBtnDiv {
    display: flex;
    padding: 30px;
    width: 100%;
    height: 108px;
    justify-content: end;
    
}



.sendBulkSMS #spinner {
    margin-left: 10px;
}

.sendBulkSMS #selectFileBtn {
    border-radius: 3px;
    width: 116px;
    height: 40px;
    font-size: 16px;
    border: 1px solid  var(--main-color);
    color:  var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendBulkSMS #fileName {
    position: relative;
    top: 10px;
    color: #888888;
    font-size: 14px;
    margin-right: 10px;
}

.sendBulkSMS #mainLogo {
    width: 160px;
}

.campaignName {
    height: 45px;
    background-color: white;
}

.receiversSectionTitle
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #D9D9D9;
}

#divTitles {
    max-width: 670px;
    width: 90%;
}

#toggleBtnLabel {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -1px;
    font-weight: 500;
    font-size: 14px;
    color: #2B3674;
    
}


.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    /* Adjust the width */
    height: 20px;
    /* Adjust the height */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 20px;
    /* Adjust border-radius */
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

.defaultWidthSize
{
    width: 52%;
}

.uploadPhoneNumbersFile
{
    display: flex;
}

input:checked+.slider {
    background-color: var(--main-color);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--main-color);
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.slider.round {
    border-radius: 20px;
}

.slider.round:before {
    border-radius: 50%;
}



  input[type=radio] {
      accent-color: var(--main-color);
  }


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media only screen and (max-width: 700px) {

    .sendBulkSMS .icons {
        top: 10px;
    }
    .mainInfoSection1
    {
       flex-direction: column;
    }
  .deafultWidthSize
  {
    width: 100%;
  }
    .isScheduleCheckBox
    {
       flex-direction: column;
    }
.campaignNameDiv
{
    width: 100%;
}
.sendBulkSMS .messageTestArea
{
    width: 200px;
}
.uploadPhoneNumbersFile
{
    width: 200px;
}

.sendBulkSMS .form
{
    width: 240px;
}
.sendBulkSMS .form input
{
    width: 100%;
}
.formLabelStyle
{
    width: 200px;
}

}

@media only screen and (max-width: 500px) {

    .wrongNumbersError {
        flex-direction: column;
    }
}