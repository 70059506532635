.updatePopUpCheckBox {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;

}

.updatePopUpCheckBox.ar {
    direction: rtl;
}

.css-j204z7-MuiFormControlLabel-root {
    margin-left: -11px !important;
    margin-right: 16px !important;
}

.paymentMethods.ar {
    float: right;
    direction: rtl;
}

.paymentMethodsLabel {
    display: flex;
    justify-content: left;

}

.paymentMethodsLabel.ar {
    display: flex;
    justify-content: right;
    direction: rtl;
}

.checkBoxLabel {
    margin-top: 10px;
    font-weight: bold;

}

.dividerStyle {
    margin-top: -12px;
}

.userPackagesOptions {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.packCard {
    background: #FFFFFF;
    box-shadow: 0px 3.77018px 49.9549px rgba(0, 0, 0, 0.1);
    border-radius: 18.8509px;
    width: 180px;
    height: 270px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 30px;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

.packsImg {
    width: 115px;
    height: 115px;
    margin: 0 auto;
}

.packCardTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: #3A3A3A;
    margin-top: 25px;
}


.operatorTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
}

.operatorsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;
}


.operatorCost {
    height: 41px;
}

.operatorName {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #000000;
    width: 15%;
}

/* .closeBtn {
    background: #FBFDFE;
    border: 0.762376px solid  var(--main-color);
    border-radius: 3.81188px;
    color:  var(--main-color);
    border-radius: 4px;
    font-size: 16px;
    width: 124px;
    padding: 10px;
    margin-right: 10px;
}

.closeBtn:hover {
    background-color:  var(--main-color);
    color: white;
} */

@media (max-width: 1200px) {

    .packCard {
        padding: 20px;
    }

}

@media (max-width: 600px) {
    .packCard {
        padding: 20px;
    }
}