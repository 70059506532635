.dlrContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}



.dlrContainer .titleReport {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}


.dlrContainer .spinnerStyle {
    margin-top: 20px;
    margin-left: 20px;

}

.dlrNumbers {
    display: flex;
    margin-bottom: 32px;
    gap: 20px;
}

.chartDiv {
    width: 20%;
    height: 100px;
}

.statisticsDlrNumbersDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.statusCardStyle {
    display: flex;
    align-items: center;
    padding: 24px;
    gap: 5px;
    height: 115px;
    width: 100%;
    max-width: 378px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 18px;
}

.statusCardTitle {
    color: var(--Text, #030229);
    opacity: 0.7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.statusDelvIconDiv {
    border-radius: 38px;
    background: #F1FFF9;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusSendIconDiv {
    border-radius: 38px;
    background: #7f7f7f14;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusFailedIconDiv {
    border-radius: 38px;
    background: #FFCACA;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chartCard {
    border-radius: 10px;
    background: #FFF;
    width: 480px;
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.chartcardDiv {
    margin-top: -50px;
}

.chartCardTitle {
    margin-top: 20px;
    width: 100%;
    color: var(--Text, #030229);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chartCardTotal {
    color: #000;
    ;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    width: 100%;
}

.statuesIcon {
    width: 32px;
    height: 32px;
}

.statusCardBody {
    display: flex;
    align-items: center;
    gap: 20px;
}

.statusCardValue {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.dlrStatusCard {
    display: flex;
    gap: 32px;
}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .dlrContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .dlrContainer .tableFilter {
        flex-direction: column;
        justify-content: space-around;
    }

    .dlrContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .dlrContainer .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }

    .dlrNumbers {
        flex-direction: column-reverse;
    }

    .dlrStatusCard {
        flex-direction: column;
    }

    .chartCard {
        width: 275px;
    }
}


@media (max-width: 400px) {

    .chartCard {
        width: 275px;
    }

    .dlrStatusCard {
        flex-direction: column;
    }

    .dlrNumbers {
        flex-direction: column-reverse;
    }


    .tableDiv {
        overflow-x: scroll;
    }
}