.wrapper {
    display: flex;
    align-items: stretch;

}

.wrapper.ar {
    direction: rtl;
    text-align: right;
}

#content {
    width: 80%;
    flex: 1;
    padding: 16px;
    min-height: 100vh;
    transition: all 0.3s;
    overflow-x: none;
}

body {
    background: var(--Secondary-Grey-300, #F4F7FE);
}





.Toastify__toast-body {
    color: black;
    font-size: 16px;
    padding-left: 20px;
    line-height: 20px;
    padding: 0px;
    padding-top: 25px;
    width: fit-content;
    font-weight: 400;
    margin-left: 25px !important;
}



.spinner-border {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

* {
    outline: none !important;
    border: none;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}

/* For Firefox */
/* You might need to enable custom scrollbars in Firefox settings */
/* about:config -> layout.css.scrollbar-width.enabled -> true */
/* Alternatively, you can use a Firefox-specific pseudo-element */
* {
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 8px;
}