.signUpContainer .mainTitle {
    text-align: start;
    font-size: 24px;
    font-weight: 500;
}

.signUpContainer .subTitle {
    max-width: 272px;
    color: #444;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 16px;
    margin-bottom: 32px;

}

#mainLoginLogoSignUp {
    width: 100%;
    height: 740px;
    position: absolute;
}

.filesUploadDivSignUp {
    display: flex;
}

.formCardSignUp {
    width: 100%;
    display: flex;
    position: relative;
    max-width: 440px;
    overflow: auto;
    background: rgba(221, 51, 51, 0.02);
    border: 1px dashed  var(--second-color);;
    border-radius: 4px;
    margin: 0 auto;
}


.uploadFileIconSignUp {
    display: flex;
    gap: 10px;
    padding: 12px;
}

.uploadCloudSignUp {
    width: 42.71px;
    height: 37px;
}

.uploadInfoSignUp {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0F0F0F;
}

.uploadFileTypeSignUp {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
}

.langDropDownListStyle {
    background-color: transparent;
    color: black;
    outline: 0;
    border: 0;
}

.langDropDownListStyle:hover {
    background-color: transparent;
    color: black;
    outline: 0;
    border: 0;
}

.signUpContainer #mainLogo {
    margin: auto;
    display: block;
    margin-bottom: 30px;
}

.signUpFrameContainer {
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 98%;
    /* max-width: 500px; */
    margin: 0 auto;
    padding-top: 48px;
    margin-top: 20px;
}

.signUpContainer.ar {
    direction: rtl;
    text-align: start;
}

.signUpContainer input {
    font-size: 14px;
}
.signUpMainContainer {
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
    /* Change this line to repeat-y */
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.signUpContainer #loginBtn {
    padding: 13px 45px;
    border-radius: 6px;
    font-size: 16px;
    background-color:  var(--main-color);
    display: block;
    color: white;
    text-align: center;
    width: 100%;
    outline: none;
    border: none;
}

#signUpBtn {
    padding: 13px 45px;
    border-radius: 6px;
    font-size: 16px;
    background-color:  var(--main-color);
    display: block;
    color: white;
    text-align: center;
    width: 100%;
    outline: none;
    border: none;
}

.signUpContainer .title {
    font-size: 14px;
    color: #888888;
    text-align: center;
    margin-bottom: 20px;
}

.signUpContainer .subTitle {
    font-size: 14px;
    color: #888888;
}


.signUpContainer #erorrMsg {
    color:  var(--main-color);
    margin-top: 20px;
}

/* a {
    color:  var(--main-color);
} */

/* a:hover {
    color:  var(--main-color);
} */


.signUpContainer input::-webkit-outer-spin-button,
.signUpContainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    #mainLoginLogoSignUp {
        position: relative !important;
        margin-top: -10%;
    }

}