.apiIntegrationMainDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 996px;
    width: 90%;
    background: #011627;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 50px;
}

.apiIntegrationCodeArea {
    color: white;
}

.optionalData{
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.apiIntegrationBtns {
    display: flex;
    align-items: left;
    gap: 10px;
}

.attemptsDiv {
    color: #FFFFFF;
    font-size: 15px;
    margin-top: 10px;
}

.apiBtnsStyle {

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background:  var(--second-color);;
    border-radius: 20px;
    width: 182px;
    height: 44px;
}

.apiTestBtnsStyle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:  var(--second-color);;
    background-color: #D33;
    color: white;
    border: 1px solid var(--red-normal, #D33);
    border-radius: 20px;
    width: 182px;
    height: 44px;
}

.apiHelpBtnsStyle {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5F6067;
    background: white;
    border: 1px solid #5F6067;
    border-radius: 20px;
    width: 182px;
    height: 44px;

}

.helpBtnDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.phoneNumberListApi{
    width: 50%;
    height: 150px;
    background-color: #011627;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    resize:none;
}

.apis-types{
    display: flex;
    align-items: center;
    gap: 10px;
}
.apis-types label{
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}

.apis-types-title{
    color: white;
    font-size: 20px;
}
.api-types-radion-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.apiContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.apiContainer .titleApi {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-left: -40px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.apiIntegrationTitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-bottom: 20px;
    word-wrap: break-word;
}

.apiTitle {
    display: flex;
}

.integrationData {
    display: flex;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 20px;

}

.integrationData .form-control {
    width: 50%;
    height: 30px;
    border-width: 0.5px;
    background-color: #011627;
    color: white;
}

.integrationData .title {
    width: 20%;
}

.integrationKey {
    color: #FFFFFF;
    font-size: 15px;
}

.apilink {
    font-size: 15px;
}


/* Responsive Media Query */
@media (max-width: 1200px) {
    .apiContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .integrationData .form-control {
        width: 80%;
    }

    .integrationData {
        flex-direction: column;
    }

    .attemptsDiv {
        display: block !important;
        margin-top: 10px;
        margin-left: 5px;
    }

}

@media (max-width: 600px) {
    .apiContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .apiIntegrationBtns {
        flex-direction: column;
        align-items: center;
    }

    .integrationData {
        flex-direction: column;
    }

    .integrationData .form-control {
        width: 100%;
    }

    .attemptsDiv {
        /* flex-direction: column; */
        display: block;
        margin-top: 0px;
        margin-left: 5px;
    }
    .helpBtnDiv{
        flex-direction: column;
    }
    .phoneNumberListApi{
        width: 100%;
    }
    .integrationData .title{
        width: auto;
    }

}