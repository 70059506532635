 .modal-dialog {
     max-width: 650px;
 }



 .campaignTypeStyle
 {
    border-radius: 4px;
    border: 1px solid #13B369;
    background: #E3F3E9;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    color: #13B369;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;  
 }

 .campaignMsg
 {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(242, 242, 242, 0.56);
    padding: 16px;
    margin-top: 20px;

 }

 .campaignMsgTitle
 {
    color: #0F0F0F;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
        
 }

 .campaignMsgContent {
    color: #606060;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

 }