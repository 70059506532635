body {
    overflow-x: hidden;
}

.rightCol {
    padding: 150px;
    display: flex;
    justify-content: center;
    background: #F8F9FE;
}

.leftCol {
    padding: 50px;
    background: white;
}

.registerationVerfiyTitle {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    color: #1C1C1C;
    margin-bottom: 30px;
}

.registerationVerfiyDesc {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #404040;
    opacity: 0.99;
    margin-bottom: 30px;
}

.styelFiled {
    width: 300px;
    height: 48px;
    margin-bottom: 90px;
}

#changePasswordImg {
    width: 432px;
    height: 432px;
}



#verfiyBtn {
    width: 301px;
    height: 48px;
    background:  var(--second-color);;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    margin-top: 48px;
}

.noteStyle {
    width: 301px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #040308;
    text-align: center;
    cursor: pointer;
}

.noteLink {
    color:  var(--second-color);;
}