.termsAndConditionsDiv {
    margin: 0 auto;
    max-width: 784px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(138, 142, 148, 0.15);
    border-radius: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    padding-bottom: 24px;

}

.termsAndConditionsTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 16px;
}

.termsAndConditionsContent {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #000000;
    margin-left: 33px;
}

.termsAndConditionsContent.ar {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #000000;
    margin-right: 33px;
}

.termsIcon {
    color:  var(--second-color);;
}