.langButtonsMainContainer {
    position: absolute;
    top: 20px;
    margin-right: 10%;
    z-index: 90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 79%;
}

.langButtonsMainContainer img {
    max-width: 113px;
    max-height: 28px;
}

.language-buttons {
    border-radius: 8px;
    background: #F0F0F0;
    padding: 4px;
    direction: ltr !important;

}

.langButton {
    border-radius: 6px;
    padding: 5px 5px;
    width: 98px;
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.24px;
}

.langButton.active {
    background-color: white;
    border: 1px solid #D9D9D9;

}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .langButtonsMainContainer {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
    }
}