
.ChartDoughnut
{
    display: flex;
    width: 240px;
    height: 240px;
    flex-direction: column;
   
}

