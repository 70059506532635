.updateAccountDiv {

    max-width: 620px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(138, 142, 148, 0.15);
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 24px;
    padding-bottom: 24px;
}

.myProfileMainContetnt {
    padding-left: 56px;
}

.UpdateAccountTitles {
    max-width: 620px;
}

.UpdateAccountTitles .welcomeTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
}

.UpdateAccountTitles .subTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    margin-bottom: 20px;
}

.subscriptionDetailes {
    background: rgba(234, 236, 240, 0.31);
    border-radius: 8px;
    max-width: 620px;
    margin-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

}

.subscriptionDetailes .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.433333px;
    color: #2E3543;
}

.subscriptionDetailes .price {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 62px;
    letter-spacing: -0.938889px;
    color: #C72E2E;
}

.subscriptionContent {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.288889px;
    color: #667085;
    margin-bottom: 10px;
}

#subscriptionImg {
    margin-top: -65px;
}

.updateAccountTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #101828;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profileUser {
    font-size: 16px;
    color:  var(--second-color);;
}

.formCardUpdate {
    width: 100%;
    display: flex;
    position: relative;
    overflow: auto;
    background: rgba(221, 51, 51, 0.02);
    border: 1px dashed  var(--second-color);;
    border-radius: 4px;

}

.saveChangesBtn {
    width: 167px;
    height: 40px;
    background:  var(--second-color);;
    border-radius: 3.81188px;
    color: #FFFFFF;
    margin-top: 20px;
}

@media only screen and (max-width: 700px) {

    .subscriptionContent {
        flex-direction: column;
    }

    #subscriptionImg {
        margin-top: 0px;
    }

    .myProfileMainContetnt {
        padding-left: 0px;
    }
}