.reportdContainer .tableMainTitles {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.reportdContainer .tableFilter {
    display: flex;
    gap: 10px;
}

.reportdContainer .titleReport {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.reportdContainer .titleFilter {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: 15px;
}

.reportdContainer .filterList {
    border: 1px solid #d1d1d1;
    color: #252b42;
    font-size: 14px;
    font-weight: 600;
    height: 45px;
    letter-spacing: .2px;
    line-height: 24px;
    margin-right: 10px;
    width: 128px;
}

.tableDiv {
    background: white !important;
    padding-bottom: 10px;
}



.tableHeaderFilter .searchFilter {
    width: 120px;
    height: 30px;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.reportdContainer .exportBtn {
    width: 128px;
    height: 44px;
    background:  var(--second-color);;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;

}


.reportdContainer .spinnerStyle {
    margin-top: 20px;
    margin-left: 20px;

}

/* Responsive Media Query */
@media (max-width: 1200px) {
    .reportdContainer .tableMainTitles {
        flex-direction: column;
        justify-content: space-around;
    }

    .reportdContainer .tableFilter {
        flex-direction: column;
        justify-content: space-around;
    }

    .reportdContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .reportdContainer .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }

}


@media (max-width: 400px) {


    .reportdContainer .filterList {
        margin-top: 20px;
        width: 100%;
    }

    .reportdContainer .exportBtn {
        margin-top: 20px;
        width: 100%;
    }

    .tableDiv {
        overflow-x: scroll;
    }
}